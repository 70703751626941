import React, { lazy, Suspense } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export const loadable = (importFunc: any) => {
  const LazyComponent = lazy(importFunc);

  return (props: any) => (
    <Suspense
      fallback={
        <Spin
          style={{ width: '100%', padding: '1em' }}
          indicator={<LoadingOutlined style={{ color: 'gray' }} />}
          size="large"
        />
      }
    >
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadable;
