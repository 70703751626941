// define the color palette to be used in the app
export const colorPalette = {
  primary1: {
    main: '#183159',
    light: '#1890ff',
    dark: '#094074',
  },
  primary2: {
    main: '#f37c21',
    light: '#8e51e3',
    dark: '#c8850f',
  },
  tertiary1: {
    main: '#ccc',
    light: '#e6e6e6',
    dark: '#262626',
  },
  tertiary2: {
    main: '#8B8B88',
    light: '#d9d9d9',
    dark: '#262626',
  },
  background: {
    main: '#f3f3f3',
  },
  text: {
    main: '#383737',
  },
  dark: {
    main: '#222',
  },
  white: {
    main: '#fff',
  },
  black: {
    main: '#000',
  },
  social: {
    facebook: '#3E5C9A',
    twitter: '#000',
    instagram: '#ac3f8d',
    linkedin: '#117BB8',
  },
};

export default colorPalette;
