import i18n from '../i18n';

export type Language = 'en' | 'ru' | 'he';

type LanguageFlag = {
  code: Language;
  url: string;
};

type LanguageFlags = {
  // eslint-disable-next-line no-unused-vars
  [key in Language]: LanguageFlag;
};

export const getCurrentLanguage = (): Language =>
  i18n.language ||
  window.localStorage.i18nextLng ||
  localStorage.getItem('language') ||
  'en';

export const languageFlags: LanguageFlags = {
  en: {
    code: 'en',
    url: '/images/us.svg',
  },
  ru: {
    code: 'ru',
    url: '/images/ru.svg',
  },
  he: {
    code: 'he',
    url: '/images/il.svg',
  },
};

export const isRtl = getCurrentLanguage() === 'he';

export const getLanguageFlag = (code: Language) => {
  const lang = languageFlags[code] || languageFlags.en;

  return lang.url;
};
