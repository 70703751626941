import { Layout, Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LanguageMenu } from './LanguageMenu';
import colorPalette from '../../utils/colorPalette';
import { PAGES } from '../../utils/consts';

const { Text } = Typography;

const StyledLayoutHeader = styled(Layout.Header)`
  height: 72px;
  padding: 0 30px;
  line-height: normal;
  position: relative;
  background-color: ${colorPalette.primary1.main};
  border-bottom: 3px solid ${colorPalette.primary2.main};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 0px;
`;
const StyledHeaderRow = styled(Row)`
  && {
    height: 100%;
  }
`;
const HeaderText = styled(Text)`
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 2;
  text-transform: uppercase;
`;
const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HeaderMenuItem = styled.div`
  && a {
    color: ${colorPalette.white.main};
    font-size: 20px;
    font-weight: 300;
    &:hover {
      opacity: 0.7;
    }
  }
`;
const HeaderMenuSeparator = styled.div`
  height: 7px;
  width: 7px;
  border-radius: 100%;
  margin: 0 10px;
  background-color: ${colorPalette.primary2.main};
`;

export function Header() {
  const { t } = useTranslation();

  return (
    <StyledLayoutHeader>
      <StyledHeaderRow justify="space-between" align="middle">
        <Col span={6}>
          <Link to={PAGES.MAIN}>
            <Row align="middle" gutter={[8, 0]} style={{ marginLeft: 110 }}>
              <Col>
                <HeaderText style={{ color: colorPalette.white.main }}>
                  EXPLAIN
                  <HeaderText style={{ color: colorPalette.primary2.main }}>
                    IL
                  </HeaderText>
                </HeaderText>
              </Col>
              <Col>
                <img
                  height={46}
                  src="/images/logo_250.png"
                  alt={t('title.name')}
                />
              </Col>
            </Row>
          </Link>
        </Col>
        <Col span={12}>
          <HeaderMenu>
            <HeaderMenuItem>
              <Link to={PAGES.MAIN}>{t('menu.home')}</Link>
            </HeaderMenuItem>
            <HeaderMenuSeparator />
            <HeaderMenuItem>
              <Link to={PAGES.POSTS}>{t('menu.posts')}</Link>
            </HeaderMenuItem>
            <HeaderMenuSeparator />
            <HeaderMenuItem>
              <Link to={PAGES.IDEAS_GALLERY}>{t('menu.ideasGallery')}</Link>
            </HeaderMenuItem>
            <HeaderMenuSeparator />
            <HeaderMenuItem>
              <Link to={PAGES.OUR_STORY}>{t('menu.ourStory')}</Link>
            </HeaderMenuItem>
            {/* <HeaderMenuSeparator />
            <HeaderMenuItem>
              <Link to={PAGES.LETTERS}>{t('menu.lettersItem')}</Link>
            </HeaderMenuItem> */}
            <HeaderMenuSeparator />
            <HeaderMenuItem>
              <Link to={PAGES.JOIN_US}>{t('menu.joinUs')}</Link>
            </HeaderMenuItem>
          </HeaderMenu>
        </Col>
        <Col span={6}>
          <LanguageMenu />
        </Col>
      </StyledHeaderRow>
    </StyledLayoutHeader>
  );
}

export default Header;
