export const PAGES = Object.freeze({
  MAIN: '/',
  POSTS: '/posts',
  OUR_STORY: '/our-story',
  IDEAS_GALLERY: '/ideas-gallery',
  JOIN_US: '/join-us',
  LETTERS: '/letters',
});

export const API = Object.freeze({
  POSTS: 'https://dummyjson.com/products',
  SERVER_URL: 'https://app-iv45hnkoza-uc.a.run.app/api/v1/products',
  GENERATE_POST:
    'https://app-iv45hnkoza-uc.a.run.app/api/v1/generate/facebook/post',
  GENERATE_LOCAL_POST:
    'http://localhost:5001/pro-israel-llm/us-central1/app/api/v1/generate/facebook/post',
  GENERATE: 'https://app-iv45hnkoza-uc.a.run.app/api/v1/generate',
  GENERATE_LOCAL:
    'http://localhost:5001/pro-israel-llm/us-central1/app/api/v1/generate',
  LETTER: 'https://app-iv45hnkoza-uc.a.run.app/api/v1/generate/letter',
  LETTER_LOCAL:
    'http://localhost:5001/pro-israel-llm/us-central1/app/api/v1/generate/facebook/post',
});

export const TOPICS = [
  'ANY',
  'IRAN',
  'HAMAS LIES',
  'HAMAS IS ISIS',
  'BRING THEM HOME',
  'THE WEST IS NEXT',
  // 'FREE GAZA FROM HAMAS', // retunr 500 Server Error
  'TOGETHER WE WILL WIN',
  'HAMAS IS RESPONSIBLE',
  'FIGHT MASSACRE DENIAL',
  'THE WORLD STANDS WITH US',
  // "ISRAEL IS COMMITTED TO INT'L LAW",  // comment just because its too long
];

export const LETTER_TOPICS = [
  {
    title: 'Right to Self-Defense',
    value: 'SELF_DEFENSE',
    description:
      'Israel has the right to defend itself against Hamas and other terrorist groups that pose a direct threat to its citizens',
  },
  {
    title: 'Humanitarian Assistance',
    value: 'HUMANITARIAN_ASSISTANCE',
    descritpion:
      'Despite the conflict, Israel is providing significant humanitarian aid to Gaza, including food, water, medicine, fuel, and shelter supplies',
  },
  {
    title: "Hamas's Role in Aid Diversion",
    value: 'HAMAS_AID_DIVERSION',
    descritpion:
      'Hamas is known to steal and divert aid intended for civilians, hindering the effectiveness of humanitarian efforts',
  },
  {
    title: 'Need for Hamas Removal',
    value: 'HAMAS_REMOVAL',
    descritpion:
      'The path to peace requires the removal of Hamas from power and the establishment of civilian control over Gaza',
  },
  {
    title: 'Importance of Direct Negotiations',
    value: 'IMPORTANCE_DIRECT_NEGOTIATIONS',
    descritpion:
      'Circumventing direct negotiations through unilateral actions could undermine peace and harm both Palestinians and Israelis',
  },
  {
    title: 'Security Concerns',
    value: 'SECURITY_CONCERNS',
    descritpion:
      "Israel's security is paramount, and its actions are in response to threats posed by Hamas and other terrorist groups",
  },
  {
    title: 'Hostage Release',
    value: 'HOSTAGE_RELEASE',
    descritpion:
      'The immediate and unconditional release of all hostages held in Gaza is a critical step towards peace',
  },
  {
    title: "Support for Israel's Right to Respond",
    value: 'RIGHT_TO_RESPOND',
    descritpion:
      "Israel's right to respond to attacks is a fundamental principle of international law",
  },
  {
    title: 'Concerns Over Aid Distribution',
    value: 'CONCERNS_OF_AID_THEFT',
    descritpion:
      'There are legitimate concerns about how aid is distributed, especially in light of potential theft and resale',
  },
  {
    title: 'Security Over Aid Prioritization',
    value: 'SECURITY_OVER_AID',
    descritpion:
      "The U.S. must prioritize security of Israel, it's closest ally",
  },
  {
    title: 'Anti-Semitism',
    value: 'ANTI_SEMITISM',
    descritpion:
      "There is a resurgence of antisemitism and it needs to be stopped for the support of US and it's allies.",
  },
  {
    title: "Historical Context of Israel's Actions",
    value: 'HISTORICAL_CONTEXT',
    descritpion:
      "It's important to understand the historical context of Israel's actions, including the response to attacks and Hamas's continued refusal to de-escalate, negotiate and release the abductees",
  },
  {
    title: 'Congressional Support for Israel',
    value: 'CONGRESSIONAL_SUPPORT',
    descritpion:
      'A significant majority of Congress members have shown support for Israel, with only a small percentage criticizing its actions or calling for a ceasefire',
  },
  {
    title: 'Importance of U.S. Engagement',
    value: 'US_ENGAGEMENT',
    descritpion:
      "The U.S. has a significant role to play in mediating peace and supporting Israel's security and humanitarian needs ",
  },
  {
    title: "Critique of Hamas's Aggression",
    value: 'HAMAS_AGGRESSION',
    descritpion:
      "Israel's actions are often in response to aggressive acts by Hamas, including attacks on army outposts and the taking of hostages. Israel's right to defend itself under international law is a fundamental argument in its favor 2.",
  },
  {
    title: 'Humanitarian Aid and Blockade',
    value: 'HUMANITARIAN_AID',
    descritpion:
      "Israel has provided significant humanitarian aid to Gaza, including food, water, and medical supplies. However, the blockade of Gaza has been criticized, with allegations that Israel is preventing the entry of critical supplies. Israel's stance on this issue is complex, involving both the provision of aid and security concerns",
  },
  {
    title: 'Legal and Moral Arguments',
    value: 'LEGAL_AND_MORAL',
    descritpion:
      "Israel's legal team has argued that the country's actions in Gaza are justified under international law, particularly in the context of self-defense against Hamas. This perspective highlights the importance of upholding the Genocide Convention and the moral obligations it entails",
  },
  {
    title: 'Peaceful Coexistence and Security',
    value: 'PEACEFUL_COEXISTENCE',
    descritpion:
      "Israel's advocates argue for a peaceful coexistence model that allows for the security of Israelis and the well-being of Palestinians. This perspective acknowledges the complexities of the conflict and the need for a solution that addresses the security concerns of Israel while also providing justice for the Palestinian people",
  },
];
