import { useEffect } from 'react';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutPage from './AboutPage/Loadable';
import JoinUsPage from './JoinUsPage/Loadable';
import { PrimaryLayout } from './Layout/PrimaryLayout';
import LettersPage from './LettersPage/Loadable';
import NotFoundPage from './NotFoundPage/Loadable';
import PostsGridPage from './PostsGridPage/Loadable';
import PostsPage from './PostsPage/Loadable';
import { initAnalytics } from '../api/FirebaseApi';
import { PAGES } from '../utils/consts';
import { getCurrentLanguage } from '../utils/language';
import { isLocalhost } from '../utils/misc';
import '../scss/main.scss';

export function App() {
  const lang = getCurrentLanguage();
  const { t } = useTranslation();

  if (!isLocalhost()) {
    initAnalytics();
  }

  useEffect(() => {
    async function loadDayjsLocale() {
      if (lang === 'ru') {
        await import('dayjs/locale/ru');
      }

      if (lang === 'he') {
        await import('dayjs/locale/he');
      }
    }

    if (lang !== 'en') {
      loadDayjsLocale().then(() => dayjs.locale(lang));
    }
  }, [lang]);

  return (
    <>
      <Helmet
        titleTemplate={`%s - ${t('title.name')}`}
        defaultTitle={t('title.name')}
        htmlAttributes={{ lang }}
        meta={[{ name: 'description', content: t('title.description') }]}
      />

      <BrowserRouter>
        <PrimaryLayout>
          <Routes>
            <Route path={PAGES.MAIN} element={<LettersPage />} />
            <Route path={PAGES.POSTS} element={<PostsPage />} />
            <Route path={PAGES.OUR_STORY} element={<AboutPage />} />
            <Route path={PAGES.IDEAS_GALLERY} element={<PostsGridPage />} />
            <Route path={PAGES.JOIN_US} element={<JoinUsPage />} />
            <Route path={PAGES.LETTERS} element={<LettersPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </PrimaryLayout>
      </BrowserRouter>
    </>
  );
}
