import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { camelCase, random, startCase } from 'lodash-es';
import { OriginalPost, Post } from '../types';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const formatDate = (
  date: dayjs.ConfigType,
  format = 'YYYY-MM-DD',
  originalFormat?: dayjs.OptionType
) => {
  if (date) {
    return dayjs(date, originalFormat).format(format);
  }

  return date;
};

const humanize = (date: dayjs.ConfigType) => {
  const end = dayjs(date);
  const duration = dayjs.duration(end.diff(dayjs())).asMinutes();
  return dayjs.duration(duration, 'minutes').humanize(true);
};

const getDiff = (
  startDate: dayjs.ConfigType,
  endDate = new Date(),
  units: dayjs.OpUnitType = 'minutes'
) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  return end.diff(start, units);
};

// https://gist.github.com/4ndrej/33ac749d26b93b230269d8f93de7ced3
const mobile = {
  Android: () => navigator.userAgent.match(/Android/i),
  BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
  iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
  Opera: () => navigator.userAgent.match(/Opera Mini/i),
  Windows: () => navigator.userAgent.match(/IEMobile/i),
  any: () =>
    mobile.Android() ||
    mobile.BlackBerry() ||
    mobile.iOS() ||
    mobile.Opera() ||
    mobile.Windows(),
  none: () => !mobile.any(),
};

const isMobile = () => mobile.any() != null;

const uuidv4 = () => {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

const shortUuid = () => uuidv4().slice(0, 8);

const deepCopy = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

const removeUndefineds = (obj: { [key: string]: any }) => {
  if (!obj) {
    return obj;
  }

  const copy = deepCopy(obj);

  Object.keys(copy).forEach((key) => {
    const recKey = key as string;
    if (copy[recKey] === undefined) {
      delete copy[recKey];
    }
  });

  return copy;
};

const isLocalhost = () =>
  window.location.href.includes('localhost') ||
  window.location.href.includes('ngrok.app') ||
  window.location.href.includes('ngrok-free.app');

const isStaging = () => window.location.href.includes('staging');

const isDevelopment = () => isStaging() || isLocalhost();

const DRAWER_WIDTH =
  isMobile() && window.matchMedia('(orientation: portrait)').matches
    ? window.screen.width
    : Math.min(window.screen.width, 402);

const getErrorMessage = (error: any) => {
  if (!error) {
    return 'Error';
  }

  if (error?.status === 429) {
    return error?.data || error.statusText;
  }

  // verys trange case only on prod
  if (error?.name === 'AxiosError') {
    return (
      error?.response?.data || error.message || error.statusText || 'Error'
    );
  }

  if (error instanceof Error) {
    return error.message;
  }

  if (error.status) {
    return error.statusText;
  }

  return JSON.stringify(error);
};

const extractTagsFromText = (text: string, numTags = 5) => {
  const words = text.toLowerCase().split(/\s+/);
  const wordFrequencies: Record<string, number> = {};

  for (const word of words) {
    // If a word starts with '#', it's considered a tag
    const tag = word.startsWith('#') ? word.slice(1) : word;
    if (tag.length < 4) {
      continue;
    }

    if (wordFrequencies[tag]) {
      wordFrequencies[tag]++;
    } else {
      wordFrequencies[tag] = 1;
    }
  }

  const sortedTags = Object.keys(wordFrequencies).sort(
    (a, b) => wordFrequencies[b] - wordFrequencies[a]
  );

  const topTags = sortedTags.slice(0, numTags);
  return topTags.map(camelCase);
};

const TAGS_DELIMITER = ['. #', '! #', '? #'];

const splitTagsAndText = (
  content: string
): Pick<Post, 'content' | 'description' | 'tags'> => {
  if (!content?.length) {
    return { tags: [], description: content, content };
  }

  const delimiterForTags = TAGS_DELIMITER.find((delimiter) =>
    content.includes(delimiter)
  );
  const index = delimiterForTags ? content.indexOf(delimiterForTags) : -1;
  const tags = delimiterForTags
    ? content
        .substring(index + 2)
        .split(' ')
        .filter((t) => t.startsWith('#'))
    : [];
  const description = delimiterForTags ? content.substring(0, index) : content;
  return { tags, description, content };
};

const convertPost = (originalPost: OriginalPost): Post => {
  if (!originalPost?.Id) {
    throw new Error('Bad Post structure');
  }

  const { Id, Content, TopicName, Network, CreatedAt } = originalPost;
  const { tags, description } = splitTagsAndText(Content);
  const shouldGenerateImage = false;
  const post: Post = {
    id: Id,
    content: Content,
    network: Network,
    topicName: startCase(TopicName?.toLowerCase()),
    createdAt: CreatedAt,
    description,
    tags,
    thumbnail: shouldGenerateImage
      ? `https://i.dummyjson.com/data/products/${random(1, 100)}/thumbnail.jpg`
      : undefined,
  };

  return post;
};

export {
  formatDate,
  humanize,
  getDiff,
  isMobile,
  uuidv4,
  shortUuid,
  deepCopy,
  removeUndefineds,
  isLocalhost,
  isStaging,
  isDevelopment,
  getErrorMessage,
  extractTagsFromText,
  splitTagsAndText,
  DRAWER_WIDTH,
  convertPost,
};
