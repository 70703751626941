import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { isLocalhost } from '../utils/misc';

let analytics: Analytics;

export const initAnalytics = () => {
  const {
    REACT_APP_FIREBASE_API_KEY,
    REACT_APP_FIREBASE_PROJECT_ID,
    REACT_APP_FIREBASE_APP_ID,
    REACT_APP_FIREBASE_MEASUREMENT_ID,
  } = process.env;

  if (REACT_APP_FIREBASE_MEASUREMENT_ID && !isLocalhost()) {
    const app = initializeApp({
      apiKey: REACT_APP_FIREBASE_API_KEY,
      appId: REACT_APP_FIREBASE_APP_ID,
      measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
      projectId: REACT_APP_FIREBASE_PROJECT_ID,
    });
    analytics = getAnalytics(app);
  }
};

export const logAnalyticsEvent = (
  eventName:
    | 'share_post'
    | 'copy_post'
    | 'error_navigator_can_share'
    | 'error_share_post'
    | 'tag_click'
    | 'generate_letter_pdf'
    | 'generate_letter_email'
    | 'generate_letter_random_details'
    | 'copy_letter_content',
  params?: any
) => {
  if (!isLocalhost()) {
    logEvent(analytics, eventName, { ...params });
  }
};
