import { ReactNode } from 'react';
import { Col, Layout, Row } from 'antd';
import { Footer } from '../../components/Layout/Footer';
import { Header } from '../../components/Layout/Header';
import { HeaderMobile } from '../../components/Layout/HeaderMobile';
import { isMobile } from '../../utils/misc';

interface PrimaryLayoutProps {
  children: ReactNode;
}

export function PrimaryLayout(props: PrimaryLayoutProps) {
  const { children } = props;

  return (
    <Layout
      style={{
        minHeight: '100vh',
        // backgroundImage: 'url(/images/background-image.webp)',
        backgroundSize: 'cover',
      }}
    >
      {isMobile() && <HeaderMobile />}
      {!isMobile() && <Header />}

      <Layout style={{ backgroundColor: 'transparent' }}>
        <Layout.Content style={{ padding: 20 }}>
          <Row align="middle" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={15} xxl={12}>
              {children}
            </Col>
          </Row>
        </Layout.Content>
        <Footer />
      </Layout>
    </Layout>
  );
}

export default PrimaryLayout;
