import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import en_US from 'antd/es/locale/en_US';
import he_IL from 'antd/es/locale/he_IL';
import ru_RU from 'antd/es/locale/ru_RU';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { App } from './containers/App';
import colorPalette from './utils/colorPalette';
import { getCurrentLanguage, isRtl } from './utils/language';
import reportWebVitals from './utils/reportWebVitals';
import './i18n';

const currentLanguage = getCurrentLanguage();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: 5 * 60 * 1000,
    },
  },
});

// init ANT DESIGN language
// init ANT DESIGN languages
let locale = en_US;
if (currentLanguage === 'ru') {
  locale = ru_RU;
}
if (currentLanguage === 'he') {
  locale = he_IL;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <HelmetProvider>
    <Suspense fallback={null}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider
          locale={locale}
          direction={isRtl ? 'rtl' : 'ltr'}
          theme={{
            token: {
              colorPrimary: colorPalette.primary1.main,
              fontFamily: "'Nunito Sans', 'Rubik',sans-serif;",
            },
          }}
        >
          <App />
        </ConfigProvider>
      </QueryClientProvider>
    </Suspense>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
