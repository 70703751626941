import { useState } from 'react';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Typography, Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LanguageMenu } from './LanguageMenu';
import colorPalette from '../../utils/colorPalette';
import { PAGES } from '../../utils/consts';
import { isRtl } from '../../utils/language';

const { Text } = Typography;

const StyledLayoutHeader = styled(Layout.Header)`
  height: 62px;
  padding: 0 20px;
  line-height: normal;
  position: relative;
  background-color: ${colorPalette.tertiary1.dark};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 0px;
`;
const StyledHeaderRow = styled(Row)`
  && {
    height: 100%;
  }
`;
const HeaderText = styled(Text)`
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 2;
  text-transform: uppercase;
`;
const StyledMenuOutlined = styled(MenuOutlined)`
  color: ${colorPalette.white.main};
  font-size: 24px;
`;
const HeaderMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;
const HeaderMenuItem = styled.div`
  margin-bottom: 4px;
  && a {
    color: ${colorPalette.white.main};
    font-size: 24px;
    font-weight: 300;
  }
`;
const StyledCloseOutlined = styled(CloseOutlined)`
  color: ${colorPalette.white.main};
  font-size: 24px;
`;

export function HeaderMobile() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { t } = useTranslation();

  const handleMenuClick = () => {
    setDrawerOpen(false);
  };

  const handleShowDrawer = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <StyledLayoutHeader>
        <StyledHeaderRow justify="space-between" align="middle">
          <Col span={20}>
            <Link to={PAGES.MAIN}>
              <Row align="middle" gutter={[8, 0]}>
                <Col>
                  <HeaderText
                    style={{
                      color: colorPalette.white.main,
                    }}
                  >
                    EXPLAIN
                    <HeaderText type="primary">IL</HeaderText>
                  </HeaderText>
                </Col>
                <Col>
                  <img
                    height={46}
                    src="/images/logo_250.png"
                    alt={t('title.name')}
                  />
                </Col>
              </Row>
            </Link>
          </Col>
          <Col span={4}>
            <Row justify="end">
              <Col onClick={handleShowDrawer}>
                <StyledMenuOutlined />
              </Col>
            </Row>
          </Col>
        </StyledHeaderRow>
      </StyledLayoutHeader>
      <Drawer
        title=""
        placement={isRtl ? 'left' : 'right'}
        onClose={handleCloseDrawer}
        open={drawerOpen}
        width={280}
        styles={{
          body: { backgroundColor: colorPalette.tertiary1.dark },
          header: { backgroundColor: colorPalette.tertiary1.dark },
        }}
        closeIcon={<StyledCloseOutlined />}
      >
        <HeaderMenu>
          <HeaderMenuItem onClick={handleMenuClick}>
            <Link to={PAGES.MAIN}>{t('menu.home')}</Link>
          </HeaderMenuItem>
          <HeaderMenuItem onClick={handleMenuClick}>
            <Link to={PAGES.POSTS}>{t('menu.posts')}</Link>
          </HeaderMenuItem>
          <HeaderMenuItem onClick={handleMenuClick}>
            <Link to={PAGES.IDEAS_GALLERY}>{t('menu.ideasGallery')}</Link>
          </HeaderMenuItem>
          <HeaderMenuItem onClick={handleMenuClick}>
            <Link to={PAGES.OUR_STORY}>{t('menu.ourStory')}</Link>
          </HeaderMenuItem>
          {/* <HeaderMenuItem onClick={handleMenuClick}>
            <Link to={PAGES.LETTERS}>{t('menu.lettersItem')}</Link>
          </HeaderMenuItem> */}
          <HeaderMenuItem onClick={handleMenuClick}>
            <Link to={PAGES.JOIN_US}>{t('menu.joinUs')}</Link>
          </HeaderMenuItem>
        </HeaderMenu>
        <LanguageMenu />
      </Drawer>
    </>
  );
}

export default HeaderMobile;
