import { Layout, Row, Typography } from 'antd';
import styled from 'styled-components';
import p from '../../../package.json';
import colorPalette from '../../utils/colorPalette';

const { Text } = Typography;

const StyledLayoutFooter = styled(Layout.Footer)`
  height: 70px;
  background-color: ${colorPalette.primary1.main};
  a,
  span {
    color: ${colorPalette.white.main} !important;
  }
`;

export function Footer() {
  return (
    <StyledLayoutFooter>
      <Row justify="center">
        <Text type="secondary" style={{ fontSize: '75%' }}>
          v.{p.version}
        </Text>
      </Row>
    </StyledLayoutFooter>
  );
}

export default Footer;
