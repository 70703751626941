import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import colorPalette from '../../utils/colorPalette';
import { getCurrentLanguage } from '../../utils/language';
import { Language, languageFlags } from '../../utils/language';
import { isMobile } from '../../utils/misc';

const LanguageItemCol = styled(Col)`
  color: ${colorPalette.white.main};
  font-size: ${isMobile() ? '18px' : '24px'};
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  ${(props: any) =>
    props['data-is-selected'] &&
    `
      color: ${colorPalette.primary2.main};
      cursor: auto;
      &:hover {
        opacity: 1;
      }
  `}
`;

export function LanguageMenu() {
  const { t, i18n } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  const changeLanguage = (code: Language) => () => {
    localStorage.setItem('language', code);
    i18n.changeLanguage(code);

    // just to correct load of rtl in config provider and dayjs locale
    window.location.reload();
  };

  const langugeMenuItems = Object.keys(languageFlags).map((code) => ({
    key: code,
    label: t(`language.short.${code}`),
  }));

  return (
    <Row gutter={[14, 0]} align="middle" justify={isMobile() ? 'start' : 'end'}>
      {langugeMenuItems.map((menuItem) => (
        <LanguageItemCol
          key={menuItem.key}
          data-is-selected={menuItem.key === currentLanguage}
          onClick={
            menuItem.key === currentLanguage
              ? () => {}
              : changeLanguage(menuItem.key as Language)
          }
        >
          {menuItem.label}
        </LanguageItemCol>
      ))}
    </Row>
  );
}

export default LanguageMenu;
